<template>
  <b-table
    :fields="tableFields"
    :items="items"
    class="compact-table"
    sticky-header
    :sort-by="sortBy"
    :sort-desc="sortDesc"
  >
    <template #cell(start_date)="tableData">
      {{ formatedDate(tableData.item.start_date) }}
    </template>

    <template #cell(end_date)="tableData">
      {{ formatedDate(tableData.item.end_date) }}
    </template>

    <template #cell(due_date)="tableData">
      {{ formatedDate(tableData.item.due_date) }}
    </template>

    <template #cell(pay_status)="tableData">
      <status-icon :value="tableData.item.pay_status" />
    </template>
  </b-table>
</template>

<script>

import {
  BTable,
} from 'bootstrap-vue'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    BTable,
    StatusIcon,
  },
  mixins: [dateMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'due_date', label: 'Due Date', sortable: true },
        { key: 'total_amount', label: 'Total Amount', sortable: true },
        { key: 'paid_amount', label: 'Paid Amount', sortable: true },
        { key: 'pay_status', label: 'Pay Status', sortable: true },
      ],
      sortBy: 'due_date',
      sortDesc: false,
    }
  },
}

</script>
