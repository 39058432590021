<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">

      <div class="text-right">
        <b-button
          variant="primary"
          :disabled="submitting || filteredItems.length === 0"
          class="mb-1"
          @click="submitRemarks"
        >
          Save Remarks&nbsp;
          <b-spinner
            v-if="submitting"
            small
            label="Small Spinner"
          />
        </b-button>
      </div>

      <p mb-5>
        Results will be updated every 30 minutes.
      </p>

      <b-table
        :items="filteredItems"
        :fields="tableFields"
        responsive
        striped
        hover
        sort-by="party"
        class="compact-table"
        show-empty
        empty-text="No records found"
      >
        <template #cell(statements_exceeding_allowed_amount_count)="data">
          <span
            :id="`party__${data.item.party}`"
            class="hover-link"
          >
            {{ data.item.statements_exceeding_allowed_amount_count }}
          </span>

          <b-popover
            v-if="data.item.statements_exceeding_allowed_amount_count > 0"
            :target="`party__${data.item.party}`"
            triggers="hover"
            placement="bottom"
            custom-class="statements_popover"
          >
            <statements-list :items="data.item.statements_exceeding_allowed_amount" />
          </b-popover>

        </template>

        <template #cell(pending_statements_count)="data">
          <span
            :id="`party___${data.item.party}`"
            class="hover-link"
          >
            {{ data.item.pending_statements_count }}
          </span>

          <b-popover
            v-if="data.item.pending_statements_count > 0"
            :target="`party___${data.item.party}`"
            triggers="hover"
            placement="bottom"
            custom-class="statements_popover"
          >
            <statements-list :items="data.item.pending_statements" />
          </b-popover>

        </template>

        <template #cell(due_statements_count)="data">
          <span
            :id="`party_due_${data.item.party}`"
            class="hover-link"
          >
            {{ data.item.due_statements_count }}
          </span>

          <b-popover
            v-if="data.item.due_statements_count > 0"
            :target="`party_due_${data.item.party}`"
            triggers="hover"
            placement="bottom"
            custom-class="statements_popover"
          >
            <statements-list :items="data.item.due_statements" />
          </b-popover>
        </template>

        <template #cell(remarks)="data">
          <b-form-textarea
            v-model="data.item.remarks"
            type="text"
            rows="2"
          />
        </template>

        <template #cell(allowed_unpaid_amount)="data">
          {{ formateNumber(data.item.allowed_unpaid_amount) }}
        </template>

        <template #cell(unpaid_amount)="data">
          {{ formateNumber(data.item.unpaid_amount) }}
        </template>

        <template #cell(allowed_max_statement_amount)="data">
          {{ formateNumber(data.item.allowed_max_statement_amount) }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, BPopover, BFormTextarea, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatMixin from '@/mixins/formatMixin'
import StatementsList from './StatementsList.vue'

export default {
  components: {
    BSpinner, BTable, BAlert, BPopover, BFormTextarea, BButton, StatementsList,
  },
  mixins: [formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      allItems: [],
      filteredItems: [],
      submitting: false,
      tableFields: [
        { key: 'party', label: 'Buyer', sortable: true },
        { key: 'allowed_unpaid_bills', label: 'Allowed Unpaid Bills', sortable: true },
        {
          key: 'unpaid_bills', label: 'Unpaid Bills', sortable: true, tdClass: this.conditionallyRed,
        },
        {
          key: 'allowed_unpaid_amount', label: 'Allowed Unpaid Amount', sortable: true, class: 'text-right',
        },
        {
          key: 'unpaid_amount', label: 'Unpaid Amount', sortable: true, tdClass: this.conditionallyRed, class: 'text-right',
        },
        {
          key: 'allowed_max_statement_amount', label: 'Allowed Max Statement Amount', sortable: true, class: 'text-right',
        },
        {
          key: 'statements_exceeding_allowed_amount_count', label: 'Statements Exceeding Allowed Amount', sortable: true, tdClass: this.conditionallyRed, class: 'text-right pr-2',
        },
        { key: 'allowed_pending_statements', label: 'Allowed Pending Statements', sortable: true },
        {
          key: 'pending_statements_count', label: 'Pending Statements', sortable: true, tdClass: this.conditionallyRed,
        },
        {
          key: 'due_statements_count', label: 'Due Statements', sortable: true, tdClass: this.conditionallyRed,
        },
        {
          key: 'remarks', label: 'Remarks', thStyle: { width: '20%', minWidth: '250px' }, tdClass: 'remarks',
        },
      ],
    }
  },
  watch: {
    filters() {
      let filteredItems
      filteredItems = this.allItems
      if (this.filters.areas) {
        filteredItems = filteredItems.filter(item => {
          if (this.filters.areas.includes(item.party_area_id)) {
            return true
          }
          return false
        })
      }
      if (this.filters.salesmans) {
        filteredItems = filteredItems.filter(item => {
          if (this.filters.salesmans.includes(item.party_salesman_id)) {
            return true
          }
          return false
        })
      }
      if (this.filters.parties) {
        filteredItems = filteredItems.filter(item => {
          if (this.filters.parties.includes(item.party_id)) {
            return true
          }
          return false
        })
      }
      if (this.filters.discounts) {
        filteredItems = filteredItems.filter(item => {
          if (this.filters.discounts.includes(item.party_discount)) {
            return true
          }
          return false
        })
      }
      this.filteredItems = filteredItems
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.loading = true
      axios.get('/accounts/default_parties')
        .then(res => {
          this.allItems = res.data.data
          this.filteredItems = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    submitRemarks() {
      this.submitting = true
      const data = {
        items: this.filteredItems.map(item => ({ party_id: item.party_id, remarks: item.remarks })),
      }
      axios.post('/accounts/default_party_remarks', data)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.submitting = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.submitting = false
        })
    },
    conditionallyRed(_, key, item) {
      if (key === 'unpaid_bills') {
        if (item.allowed_unpaid_bills && item.unpaid_bills > item.allowed_unpaid_bills) {
          return 'red-highlight'
        }
      }
      if (key === 'unpaid_amount') {
        if (item.allowed_unpaid_amount && item.unpaid_amount > item.allowed_unpaid_amount) {
          return 'red-highlight'
        }
      }
      if (key === 'statements_exceeding_allowed_amount_count') {
        if (item.allowed_max_statement_amount && item.statements_exceeding_allowed_amount_count > 0) {
          return 'red-highlight'
        }
      }
      if (key === 'pending_statements_count') {
        if (item.pending_statements_count > item.allowed_pending_statements) {
          return 'red-highlight'
        }
      }
      if (key === 'due_statements_count') {
        if (item.due_statements_count > 0) {
          return 'red-highlight'
        }
      }
      return ''
    },
  },
}
</script>

<style>
.statements_popover {
    max-width: 1000px;
    border: 1px solid #7367F0;
    border-radius: 1px;
}

.hover-link {
    color: #7367F0;
    text-decoration: underline;
}

.remarks textarea{
  padding: 0.3rem !important
}
</style>
