<template>
  <b-card title="Default Parties">
    <default-parties-filters
      @filtersUpdated="filtersChangeHandler"
    />
    <default-parties :filters="filters" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import DefaultParties from '../../components/DefaultParties/DefaultParties.vue'
import DefaultPartiesFilters from '../../components/DefaultParties/DefaultPartiesFilters.vue'

export default {
  components: {
    BCard,
    DefaultParties,
    DefaultPartiesFilters,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
